import { useIsTabVisible } from 'hooks/useIsTabVisible';
import { useEffect } from 'react';
import { POLL_PERIOD } from './constants';

export const useUpdateSelectedTask = (
  setIsViewing: (taskId: string, isViewing: boolean) => Promise<void>,
  selectedTask: string | null,
) => {
  const isTabVisible = useIsTabVisible();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: setIsViewing
  useEffect(() => {
    if (selectedTask) {
      const timer = setInterval(() => {
        if (document.hasFocus()) {
          void setIsViewing(selectedTask, true);
        }
      }, POLL_PERIOD);

      return () => {
        clearInterval(timer);
        void setIsViewing(selectedTask, false);
      };
    }
    return () => undefined;
  }, [selectedTask]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: setIsViewing
  useEffect(() => {
    if (isTabVisible && selectedTask) {
      void setIsViewing(selectedTask, true);
    }
  }, [isTabVisible, selectedTask]);
};
