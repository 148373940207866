import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLinkRequestId } from '@packfleet/apollo-link-request-id';
import { SentryLink } from 'apollo-link-sentry';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import { useAuthProvider } from 'hooks/useAuthProvider';
import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { logout } from 'utilities/request/auth';
import { Routes, linkTo } from 'utilities/routes';
import { apolloConfig } from './config';
import { errorLink, logoutLink } from './error';

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;
export const WEBSOCKETS_BASE_URL = process.env.NEXT_PUBLIC_WEBSOCKETS_URL;
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export let apolloClient: ApolloClient<any>;

export function initializeApollo(
  apiBaseURL: string | undefined,
  websocketsBaseURL: string | undefined,
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  initialState: Object = {},
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  headers: Object = {},
  onLogout: () => void = () => undefined,
) {
  const _apolloClient =
    apolloClient ??
    createApolloClient(apiBaseURL, websocketsBaseURL, headers, onLogout);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Deep-merge the state so we keep all the objects
    const result = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(result);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  apolloClient = _apolloClient;
  return apolloClient;
}

function createApolloClient(
  apiBaseURL: string | undefined,
  websocketsBaseURL: string | undefined,
  headers = {},
  onLogout: () => void,
): ApolloClient<any> {
  const wsLink =
    typeof window !== 'undefined'
      ? new GraphQLWsLink(
          createClient({ url: `${websocketsBaseURL}/subscriptions` }),
        )
      : null;

  const httpLink = new HttpLink({
    uri: `${apiBaseURL}/graphql`,
    headers: headers,
    fetchOptions: {
      credentials: 'include',
    },
  });

  const splitLink =
    wsLink != null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          wsLink,
          httpLink,
        )
      : httpLink;

  const link = ApolloLink.from([
    new ApolloLinkRequestId(),
    errorLink,
    logoutLink(onLogout),
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true,
        includeFetchResult: true,
      },
    }),
    splitLink,
  ]);
  return new ApolloClient(apolloConfig(link));
}

export function addApolloState(client: ApolloClient<any>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const router = useRouter();
  const { idToken } = useAuthProvider();
  let state: any;
  if (pageProps) {
    state = pageProps[APOLLO_STATE_PROP_NAME];
  }

  const handleLogout = async () => {
    await logout();

    // Nothing to be done if the user is already on the login page
    if (router.pathname === Routes.login) return;

    // hard reload so entire app is re-loaded and tokens reset
    window.location.href = linkTo(Routes.login, {
      // router.asPath may not be ready if we log out quickly
      redirect_uri: window.location.pathname + window.location.search,
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: handleLogout
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: idToken
  return useMemo(
    () =>
      initializeApollo(
        API_BASE_URL,
        WEBSOCKETS_BASE_URL,
        state,
        {},
        handleLogout,
      ),
    [state, idToken],
  );
}
