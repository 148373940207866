import { User, getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useAuthProvider = () => {
  const _router = useRouter();
  const auth = getAuth();
  const user = auth.currentUser;

  const [authUser, setAuthUser] = useState<User | null>(user);
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: auth.onAuthStateChanged
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setLoading(false);
      setAuthUser(user);
      if (user && user.emailVerified) {
        const token = await user.getIdToken();
        setIdToken(token);
      }
    });
  }, []);

  return {
    loading,
    authUser,
    idToken,
  };
};
