import urlcat from 'urlcat';

export enum Routes {
  home = '/',
  login = '/login',

  dashboard = '/dashboard',
  print = '/print',

  routing = '/routing',
  routeActive = '/routing/fresh/active/:date',
  route = '/routing/fresh/:routeId',
  routeActiveDriver = '/routing/fresh/active/:date/:vehicleId',
  routeDriver = '/routing/fresh/:routeId/:vehicleId',
  routeActiveDriverActivity = '/routing/fresh/active/:date/:vehicleId/:activityId',
  routeDriverActivity = '/routing/fresh/:routeId/:vehicleId/:activityId',

  drivers = '/drivers',
  newDriver = '/drivers/new',
  driver = '/drivers/:id',
  overrideCode = '/override-code',
  warehouse = '/warehouse',
  warehouseLabels = '/warehouse/labels',
  warehouseTrolleyLabels = '/warehouse/trolley-labels',
  users = '/users',
  user = '/users/:id',
  userNew = '/users/new',
  userShipments = '/users/:id/shipments',
  merchants = '/merchants',
  things = '/things',
  merchant = '/merchants/:id',
  merchantShipments = '/merchants/:id/shipments',
  merchantExternalShipments = '/merchants/:id/external-shipments',
  shipments = '/shipments',
  shipment = '/shipments/:id',
  externalShipments = '/external-shipments',
  externalShipment = '/external-shipments/:id',
  merchantCollections = '/merchants/:id/collections',
  merchantNewCollection = '/merchants/:id/collections/new',
  merchantCollection = '/merchants/:id/collections/:collectionId',
  merchantCollectionLocations = '/merchants/:id/collection-locations',
  merchantCollectionLocation = '/merchants/:id/collection-locations/:locId',
  merchantBrands = '/merchants/:id/brands',
  merchantBrand = '/merchants/:id/brands/:brandId',
  merchantEditRecurringCollections = '/merchants/:id/collections/recurring',
  merchantUsers = '/merchants/:id/users',
  merchantPricing = '/merchants/:id/pricing',
  merchantFees = '/merchants/:id/fees',
  merchantFee = '/merchants/:id/fees/:feeId',
  pricing = '/pricing/',
  pricingDetail = '/pricing/:id',
  pricingNew = '/pricing/new',
  broadcastMessages = '/broadcast-messages',
  broadcastMessageNew = '/broadcast-messages/new',
  broadcastMessage = '/broadcast-messages/:id',
  areaOverrides = '/area-overrides',
  areaOverridesNew = '/area-overrides/new',
  areaOverridesDetail = '/area-overrides/:id',

  tasks = '/tasks',
  task = '/tasks/:id',

  claims = '/claims',
  claim = '/claims/:id',

  paymentRuns = '/payment-runs',
  paymentRun = '/payment-runs/:id',
  paymentRunRecurringItems = '/payment-runs/recurring-items',
  paymentRunRecurringItemNew = '/payment-runs/recurring-items/new',
  paymentRunRecurringItem = '/payment-runs/recurring-items/:id',

  employers = '/employers',
  employer = '/employers/:id',

  deliveryVehicles = '/delivery-vehicles',
  deliveryVehicle = '/delivery-vehicles/:id',

  dspInvoices = '/dsp-invoices',
  dspInvoice = '/dsp-invoices/:invoiceId',

  rotas = '/rotas',

  teams = '/teams',
  team = '/teams/:id',

  taskAssignmentRoles = '/roles',
  taskAssignmentRole = '/roles/:id',

  knowledge = '/knowledge',
  knowledgePage = '/knowledge/page/:id/:slug',
  knowledgePageNoSlug = '/knowledge/page/:id',

  apiLogin = '/auth/session-login',
  apiLogout = '/auth/session-logout',
  apiLoginAs = '/auth/login-as',
  apiLogoutAs = '/auth/logout-as',
  apiGenerateCSV = '/routing/generate-csv',
  apiGenerateOptimizedCSV = '/routing/generate-csv/optimized',
  apiGenerateWarehousePackLocationLabels = '/warehouse/pack-location-labels/generate',
  apiGenerateWarehouseTrolleyLabels = '/warehouse/trolley-labels/generate',
  apiGeneratePayrollExport = '/rota-cloud-shifts/payroll-export',
  apiDownloadWorkerSelfInvoice = '/internal-api/earnings/invoice/:paymentRunId/:userId',
  apiDownloadMerchantInvoice = '/billing/invoice/:organizationId/:startDate/:endDate',
  apiDownloadDspInvoice = '/internal-api/dsp-invoice/:invoiceId',
  apiDownloadRota = '/internal-api/shifts/export/:minDate/:maxDate/:roles',

  apiMerchantGenerateLabels = '/merchant-api/labels/generate',
  apiMerchantGenerateExternalLabels = '/merchant-api/labels/generate-external',
  apiMerchantAdminSendVerificationEmail = '/merchant-api/admin/send-email-verification',
  apiMerchantAdminManuallyVerifyEmail = '/merchant-api/admin/manually-verify-email',
}

export function linkTo(
  path: Routes,
  params: { [key: string]: string | string[] | undefined } = {},
) {
  return urlcat('', path, params);
}
