import { useCurrentUser } from '@/hooks/useCurrentUser';
import { Temporal } from '@js-temporal/polyfill';
import {
  isInstantAfter,
  isInstantBefore,
  now,
  parseInstant,
  parseOptionalInstant,
} from '@packfleet/datetime';
import {
  StaffMemberFragment,
  TaskInfoFragment,
  UserFragment,
} from 'generated/graphql';
import {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { IconType } from 'react-icons';
import { Colors } from 'utilities/colors';
import { useRequiredContext } from 'utilities/context';
import { truthy } from 'utilities/truthy';
import { TaskInfoError, TaskInfoTask } from './types';
import { useTasksInfo } from './useTasksInfo';
import { useUpdateSelectedTask } from './useUpdateSelectedTask';

export type MapInfo = {
  markers: {
    id: string;
    routeDate: string;
    title: string;
    copyText?: string;
    location: { lat: number; lng: number };
    icon?: IconType;
    backgroundColor?: keyof typeof Colors;
    borderColor?: keyof typeof Colors;
    popupText?: string[];
  }[];
};

type State = {
  sidebarOpen: boolean;
  selectedTaskId: string | null;
  mapInfo: MapInfo | null;
  viewedTaskIds: Record<string, true>;
  hideTaskIdsUntil: Record<string, Temporal.Instant>;
};

type Action =
  | { type: 'TOGGLE_SIDEBAR'; payload: void }
  | { type: 'SELECT_TASK'; payload: { taskId: string | null } }
  | { type: 'CLEAR_TASK_INFO_ON_MAP'; payload: void }
  | { type: 'DISPLAY_TASK_INFO_ON_MAP'; payload: MapInfo }
  | { type: 'OPTIMISTIC_COMPLETION'; payload: { taskId: string } };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
        selectedTaskId: null,
      };
    case 'SELECT_TASK':
      return {
        ...state,
        selectedTaskId: action.payload.taskId,
        viewedTaskIds: {
          ...state.viewedTaskIds,
          [action.payload.taskId || '']: true,
        },
      };
    case 'OPTIMISTIC_COMPLETION':
      return {
        ...state,
        selectedTaskId: null,
        hideTaskIdsUntil: {
          ...state.hideTaskIdsUntil,
          // Hide for long enough for async pub/sub to happen
          // and the task list to refresh, but not too long
          // so the task doesn't get ignored for ages
          [action.payload.taskId]: Temporal.Now.instant().add({ seconds: 30 }),
        },
      };
    case 'DISPLAY_TASK_INFO_ON_MAP':
      return {
        ...state,
        mapInfo: action.payload,
      };
    case 'CLEAR_TASK_INFO_ON_MAP':
      return {
        ...state,
        mapInfo: null,
      };
  }
  return state;
};

const getActions = (dispatch: Dispatch<Action>) => ({
  toggleSidebar: () => dispatch({ type: 'TOGGLE_SIDEBAR', payload: undefined }),
  optimisticCompletion: (taskId: string) =>
    dispatch({ type: 'OPTIMISTIC_COMPLETION', payload: { taskId } }),
  selectTask: (taskId: string | null) =>
    dispatch({ type: 'SELECT_TASK', payload: { taskId } }),
  clearTaskInfoOnMap: () =>
    dispatch({ type: 'CLEAR_TASK_INFO_ON_MAP', payload: undefined }),
  displayTaskInfoOnMap: (payload: MapInfo) =>
    dispatch({ type: 'DISPLAY_TASK_INFO_ON_MAP', payload }),
});

type Actions = ReturnType<typeof getActions>;
export type EnrichedTaskInfo = Omit<
  TaskInfoTask,
  'snoozeUntil' | 'createdAt'
> & {
  isSnoozed: boolean;
  snoozeUntil: Temporal.Instant | undefined;
  createdAt: Temporal.Instant;
  urgency: TaskUrgency;

  // If set, it's the name of the role that is assigned to the task
  assignedToMyRole: boolean;
  assignedToMyRoleName: string | null | undefined;

  // If set, it's the name of the team that is assigned to the task
  assignedToMyTeam: boolean;
  assignedToMyTeamName: string | null | undefined;
};

export type TaskCounts = {
  mine: number;
  mineAndTeamNonUrgent: number;
  team: number;
  snoozed: number;
  all: number;
};

type TasksContext = {
  state: State;
  action: Actions;
  error: TaskInfoError;
  tasks: Array<EnrichedTaskInfo> | undefined;
  counts: TaskCounts | null;
  selectedTask: EnrichedTaskInfo | undefined;
  nextSnoozeChange: Temporal.Instant | null;
};

const TasksContext = createContext<TasksContext | null>(null);

const initialState: State = {
  sidebarOpen: false,
  selectedTaskId: null,
  mapInfo: null,
  viewedTaskIds: {},
  hideTaskIdsUntil: {},
};

type Props = { children: ReactNode };

export const TasksContextProvider = ({ children }: Props) => {
  const user = useCurrentUser();
  const teams = user?.staffMember?.teams;
  const activeShifts = user?.staffMember?.taskAssignmentShifts;

  const [state, dispatch] = useReducer(reducer, initialState);
  const { error, tasks, setIsViewing } = useTasksInfo();
  useUpdateSelectedTask(setIsViewing, state.selectedTaskId);
  const [recompute, setRecompute] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: dispatch, recompute
  const context = useMemo((): TasksContext => {
    const enrichedTasks = enrichTasks(tasks, teams, activeShifts, user)
      ?.reverse()
      .filter(
        (task) =>
          !(task.id in state.hideTaskIdsUntil) ||
          isInstantBefore(
            state.hideTaskIdsUntil[task.id],
            Temporal.Now.instant(),
          ),
      );

    return {
      state,
      action: getActions(dispatch),
      error,
      tasks: enrichedTasks,
      nextSnoozeChange: getNextSnoozeChange(enrichedTasks),
      selectedTask: state.selectedTaskId
        ? enrichedTasks?.find((task) => task.id === state.selectedTaskId)
        : undefined,
      counts: getNotificationCount(enrichedTasks),
    };
  }, [state, dispatch, error, tasks, recompute, teams, activeShifts, user]);

  // Ensure that isSnoozed is updated once snoozes expire
  useEffect(() => {
    if (context.nextSnoozeChange) {
      const ms = now().until(context.nextSnoozeChange).total('milliseconds');
      const timeout = setTimeout(() => setRecompute((c) => c + 1), ms);
      return () => clearTimeout(timeout);
    }
  }, [context.nextSnoozeChange]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key?.toLowerCase() === 'i' && (e.metaKey || e.ctrlKey)) {
        context.action.toggleSidebar();
        return;
      }

      if (!open) return;
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [context]);

  return (
    <TasksContext.Provider value={context}>{children}</TasksContext.Provider>
  );
};

export const useTasksContext = () => useRequiredContext(TasksContext);

export const filterMyUrgentTasks = (task: EnrichedTaskInfo) =>
  task.assignedToMyRole &&
  task.urgency === TaskUrgency.Urgent &&
  !task.isSnoozed;

export const filterTeamUrgentTasks = (task: EnrichedTaskInfo) =>
  task.assignedToMyTeam &&
  task.urgency === TaskUrgency.Urgent &&
  !task.isSnoozed;

export const filterMineAndTeamNonUrgentTasks = (task: EnrichedTaskInfo) =>
  (task.assignedToMyRole || task.assignedToMyTeam) &&
  task.urgency === TaskUrgency.NonUrgent &&
  !task.isSnoozed;

export const filterSnoozedTasks = (task: EnrichedTaskInfo) => task.isSnoozed;

function getNotificationCount(
  tasks: EnrichedTaskInfo[] | null | undefined,
): TaskCounts | null {
  if (!tasks) {
    return null;
  }

  return {
    mine: tasks.filter(filterMyUrgentTasks).length,
    mineAndTeamNonUrgent: tasks.filter(filterMineAndTeamNonUrgentTasks).length,
    team: tasks.filter(filterTeamUrgentTasks).length,
    snoozed: tasks.filter(filterSnoozedTasks).length,
    all: tasks.length,
  };
}

export enum TaskUrgency {
  Urgent = 0,
  NonUrgent = 1,
}

const getTaskUrgency = ({ task }: TaskInfoFragment): TaskUrgency => {
  switch (task.__typename) {
    case 'AddressFeedbackFromDriver':
    case 'PackStuckInDepot':
    case 'ShipmentNoLocationInDepot':
    case 'ShipmentOnHold':
      return TaskUrgency.NonUrgent;
    case 'ClaimReviewRequired': {
      if (task.claimExternalShipmentId && !task.externalReference) {
        return TaskUrgency.Urgent;
      }
      return TaskUrgency.NonUrgent;
    }
    default:
      return TaskUrgency.Urgent;
  }
};

const enrichTasks = (
  tasks: TaskInfoFragment[] | null | undefined,
  teams: StaffMemberFragment['teams'] | undefined,
  activeShifts: StaffMemberFragment['taskAssignmentShifts'] | undefined,
  currentUser: UserFragment | null | undefined,
): EnrichedTaskInfo[] | undefined => {
  const myRoleTaskTypes = Object.fromEntries(
    activeShifts?.flatMap((shift) =>
      shift.taskRole?.assignedTaskTypes.map(
        (type) => [type, shift.taskRole.name] as const,
      ),
    ) ?? [],
  );
  const myTeamIds = teams?.map((team) => team.id) ?? [];
  const myTeamTaskTypes = Object.fromEntries(
    teams?.flatMap((team) =>
      team.roles.flatMap((role) =>
        role.assignedTaskTypes.map((type) => [type, team.name] as const),
      ),
    ) ?? [],
  );

  return tasks?.map((task) => {
    // Tasks can be explicitly assigned to a user or team, or they can be
    // implicitly assigned to a role or team by their type
    const explicitlyAssigned = !!task.assignedToUser || !!task.assignedToTeam;
    const explicitlyAssignedToMe = task.assignedToUser?.id === currentUser?.id;
    const explicitlyAssignedToMyTeam =
      !!task.assignedToTeam?.id && myTeamIds.includes(task.assignedToTeam?.id);

    return {
      ...task,
      assignedToMyRole: explicitlyAssigned
        ? explicitlyAssignedToMe
        : task.taskType in myRoleTaskTypes,
      assignedToMyRoleName: explicitlyAssigned
        ? task.assignedToUser?.name
        : myRoleTaskTypes[task.taskType] || null,
      assignedToMyTeam: explicitlyAssigned
        ? explicitlyAssignedToMyTeam
        : task.taskType in myTeamTaskTypes,
      assignedToMyTeamName: explicitlyAssigned
        ? task.assignedToTeam?.name
        : myTeamTaskTypes[task.taskType],
      snoozeUntil: parseOptionalInstant(task.snoozeUntil),
      createdAt: parseInstant(task.createdAt),
      urgency: getTaskUrgency(task),
      isSnoozed: task.snoozeUntil
        ? isInstantAfter(parseInstant(task.snoozeUntil), now())
        : false,
    };
  });
};

const getNextSnoozeChange = (tasks: EnrichedTaskInfo[] | null | undefined) => {
  if (!tasks) {
    return null;
  }

  const snoozeUntilTimes = tasks
    .map((task) => task.snoozeUntil)
    .filter(truthy)
    .filter((instant) => isInstantAfter(instant, now()))
    .map((instant) => instant.epochMilliseconds);

  if (snoozeUntilTimes.length === 0) {
    return null;
  }

  const minSnoozeUntil = Math.min(...snoozeUntilTimes);
  return Temporal.Instant.fromEpochMilliseconds(minSnoozeUntil);
};
