import {
  BillingLineItemCategory,
  ExternalShipmentServiceType,
  PackStatus,
  ShipmentServiceType,
} from '../generated/graphql';
import { ExternalCarrierCode } from './external-shipments';

export const PACK_STATUS_ORDERING: Record<PackStatus, number> = {
  [PackStatus.Scheduled]: 0,
  [PackStatus.OutForCollection]: 1,
  [PackStatus.Collected]: 2,
  [PackStatus.InDepot]: 3,
  [PackStatus.ExternalCarrierCollected]: 4,
  [PackStatus.ExternalCarrierInTransit]: 5,
  [PackStatus.OutForDelivery]: 6,
  [PackStatus.AvailableForRecipientPickup]: 7,
  [PackStatus.Delivered]: 8,
  [PackStatus.FailedDelivery]: 9,
  [PackStatus.OnHold]: 10,
  [PackStatus.ToReturnToSender]: 11,
  [PackStatus.ReturnedToSender]: 12,
  [PackStatus.Undeliverable]: 13,
};

export const INTERNAL_TRACKING_NUMBER_REGEX = /([a-z]+-)+[a-z]+/;

export function formatPackStatus(status: PackStatus): string;
export function formatPackStatus(status: undefined): null;
export function formatPackStatus(
  status: PackStatus | undefined,
): string | null {
  if (!status) {
    return null;
  }

  return {
    [PackStatus.Scheduled]: 'Booked for collection',
    [PackStatus.OutForCollection]: 'Out for collection',
    [PackStatus.Collected]: 'Collected',
    [PackStatus.InDepot]: 'In depot',
    [PackStatus.ExternalCarrierCollected]: 'Collected by external carrier',
    [PackStatus.ExternalCarrierInTransit]: 'In transit by external carrier',
    [PackStatus.OutForDelivery]: 'Out for delivery',
    [PackStatus.AvailableForRecipientPickup]: 'Available for recipient pickup',
    [PackStatus.Delivered]: 'Delivered',
    [PackStatus.FailedDelivery]: 'Delivery failed',
    [PackStatus.OnHold]: 'On hold',
    [PackStatus.ToReturnToSender]: 'Returning to sender',
    [PackStatus.ReturnedToSender]: 'Returned to sender',
    [PackStatus.Undeliverable]: 'Undeliverable',
  }[status];
}

export function getPackStatusColor(status: PackStatus) {
  return {
    [PackStatus.Scheduled]: 'info',
    [PackStatus.OutForCollection]: 'info',
    [PackStatus.Collected]: 'brandDark',
    [PackStatus.InDepot]: 'neutral',
    [PackStatus.ExternalCarrierCollected]: 'neutral',
    [PackStatus.ExternalCarrierInTransit]: 'neutral',
    [PackStatus.OutForDelivery]: 'warning',
    [PackStatus.AvailableForRecipientPickup]: 'warning',
    [PackStatus.Delivered]: 'success',
    [PackStatus.FailedDelivery]: 'danger',
    [PackStatus.OnHold]: 'accent',
    [PackStatus.ToReturnToSender]: 'danger',
    [PackStatus.ReturnedToSender]: 'info',
    [PackStatus.Undeliverable]: 'danger',
  }[status] as
    | 'info'
    | 'brandDark'
    | 'brandBright'
    | 'neutral'
    | 'warning'
    | 'success'
    | 'danger';
}

export function shipmentScanURL(trackingNumber: string, packNumber: number) {
  return `${process.env.NEXT_PUBLIC_MERCHANT_BASE_URL}/scan/${trackingNumber}/manage?pack=${packNumber}`;
}

export function getNextLogicalPackStatus(status: PackStatus) {
  return {
    [PackStatus.Scheduled]: PackStatus.Collected,
    [PackStatus.OutForCollection]: PackStatus.Collected,
    [PackStatus.Collected]: PackStatus.InDepot,
    [PackStatus.InDepot]: PackStatus.OutForDelivery,
    [PackStatus.ExternalCarrierCollected]: PackStatus.ExternalCarrierInTransit,
    [PackStatus.ExternalCarrierInTransit]: PackStatus.OutForDelivery,
    [PackStatus.OutForDelivery]: PackStatus.Delivered,
    [PackStatus.AvailableForRecipientPickup]: PackStatus.Delivered,
    [PackStatus.Delivered]: PackStatus.FailedDelivery,
    [PackStatus.FailedDelivery]: PackStatus.InDepot,
    [PackStatus.OnHold]: PackStatus.ToReturnToSender,
    [PackStatus.ToReturnToSender]: PackStatus.ReturnedToSender,
    [PackStatus.ReturnedToSender]: PackStatus.InDepot,
    [PackStatus.Undeliverable]: PackStatus.Undeliverable,
  }[status];
}

export function packStatusLocationIdRequirements(
  status: PackStatus,
): 'required' | 'optional' | 'unavailable' {
  switch (status) {
    case PackStatus.InDepot:
      return 'required';
    case PackStatus.ToReturnToSender:
    case PackStatus.OnHold:
      return 'optional';
    default:
      return 'unavailable';
  }
}

export function formatServiceType(
  st: ShipmentServiceType | null | undefined,
): string | null {
  switch (st) {
    case ShipmentServiceType.NextDay:
      return 'Next day';
    case ShipmentServiceType.TwoDay:
      return 'Two day';
    case ShipmentServiceType.SameDay:
      return 'Same day by 10pm';
    case null:
    case undefined:
      return null;
  }
}

export function formatExternalCarrierServiceType(
  carrierCode: string,
  st: ExternalShipmentServiceType,
): string | null {
  switch (st) {
    case ExternalShipmentServiceType.NextDay:
      return 'Next day';
    case ExternalShipmentServiceType.NextDayDeferredScotlandMainland:
      return (
        'Two Day – Scotland Mainland' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone B)' : '')
      );
    case ExternalShipmentServiceType.NextDay9am:
      return 'Next day 9am';
    case ExternalShipmentServiceType.NextDay12pm:
      return 'Next day 12pm';
    case ExternalShipmentServiceType.NextDay1030am:
      return 'Next day 10:30am';
    case ExternalShipmentServiceType.TwoDay:
      return 'Two day (Zone C)';
    case ExternalShipmentServiceType.Saturday:
      return 'Saturday';
    case ExternalShipmentServiceType.Saturday9am:
      return 'Saturday 9am';
    case ExternalShipmentServiceType.Saturday12pm:
      return 'Saturday 12pm';
    case ExternalShipmentServiceType.Saturday1030am:
      return 'Saturday 10:30am';
    case ExternalShipmentServiceType.Sunday:
      return 'Sunday';
    case ExternalShipmentServiceType.Sunday9am:
      return 'Sunday 9am';
    case ExternalShipmentServiceType.Sunday12pm:
      return 'Sunday 12pm';
    case ExternalShipmentServiceType.Sunday1030am:
      return 'Sunday 10:30am';
    case ExternalShipmentServiceType.TwoDayHighlandsAndIslands:
      return (
        'Two Day – Highlands and Islands' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone D)' : '')
      );
    case ExternalShipmentServiceType.TwoDayNorthernIreland:
      return (
        'Two Day – Northern Ireland' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone C)' : '')
      );
    case ExternalShipmentServiceType.TwoDayOffShore:
      return (
        'Two Day Off Shore' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone D)' : '')
      );
    case ExternalShipmentServiceType.NextDayDeferredOffShore:
      return (
        'Next Day Deferred - Off Shore' +
        (carrierCode === ExternalCarrierCode.dhlUK
          ? ' (Zone D + extra charge)'
          : '')
      );
    case ExternalShipmentServiceType.NextDayDeferredHighlandsAndIslands:
      return (
        'Next Day Deferred - Highlands and Islands' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone D)' : '')
      );
    case ExternalShipmentServiceType.ThreeDay:
      return 'Three Day';
    case ExternalShipmentServiceType.ThreeDayHighlandsAndIslands:
      return (
        'Three Day - Highlands and Islands' +
        (carrierCode === ExternalCarrierCode.dhlUK ? ' (Zone D)' : '')
      );
    case null:
    case undefined:
      return null;
  }
}

export function formatBillingLineItemCategory(
  c: BillingLineItemCategory | null | undefined,
): string | null {
  switch (c) {
    case BillingLineItemCategory.AdditionalPacks:
      return 'Additional packs';
    case BillingLineItemCategory.Adjustment:
      return 'Adjustment';
    case BillingLineItemCategory.BaseShipment:
      return 'Base shipment';
    case BillingLineItemCategory.CollectionFee:
      return 'Collection fee';
    case BillingLineItemCategory.DeliveryTimeWindow:
      return 'Delivery time window';
    case BillingLineItemCategory.PackfleetPro:
      return 'Packfleet Pro';
    case BillingLineItemCategory.RevenueShare:
      return 'Revenue share';
    case BillingLineItemCategory.Claim:
      return 'Claim';
    case BillingLineItemCategory.Uncategorised:
      return 'Uncategorised';
    case BillingLineItemCategory.FuelAndEnergySurcharge:
      return 'Fuel & Energy surcharge';
    case BillingLineItemCategory.ReturnPacks:
      return 'Return packs';
    case null:
    case undefined:
      return null;
  }
}

export function getServiceTypeClassNames(
  st: ShipmentServiceType | null | undefined,
): string | null {
  switch (st) {
    case ShipmentServiceType.NextDay:
      return 'bg-secondary';
    case ShipmentServiceType.TwoDay:
      return 'bg-info text-primaryInverted';
    case ShipmentServiceType.SameDay:
      return 'bg-warning text-primaryInverted';
    case null:
    case undefined:
      return null;
  }
}
